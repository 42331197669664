<template>
	<base-col col="12" class="usage-info-col">
		<div class="product-usage-info" :class="{ reversed: isReverseFlex }">
			<div class="text-wrapper">
				<div class="text">
					<base-font variant="medium-header" tag="h2">
						{{ value.heading }}
					</base-font>
					<base-font :html="value.description" />
				</div>
			</div>
			<div class="image-wrapper">
				<cms-image class="image" :class="{ 'image-left': isReverseFlex }" :src="value.image" :base="{width: 300}" :md="{width: 460, height: 280}" :xl="{width: 560, height: 280}" :xxl="{width: 705, height: 280}"/>
			</div>
		</div>
	</base-col>
</template>

<script>
export default {
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		isReverseFlex () {
			if (process.server) return false
			return this.value.reversed
		}
	}
}
</script>

<style scoped lang="scss">
.product-usage-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4rem;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
		min-height: 280px;
		margin-top: 8rem;
	}
	@include media-breakpoint-up(xxl) {
		height: 400px;
	}

	.text-wrapper {
		width: 100%;
		flex: 0 1 70%;

		@include media-breakpoint-up(lg) {
			margin-bottom: 2rem;
			flex: 1 0 50%;
		}

		@include media-breakpoint-up(xl) {
			width: 700px;
		}
	}

	.text {
		margin: auto;
		text-align: center;
		letter-spacing: 0.48px;
		line-height: 20px;
	}

	.image-wrapper {
		width: 100vw;
		margin-top: 1rem;

		@include media-breakpoint-up(lg) {
			height: 280px;
			margin: 0;
		}
		@include media-breakpoint-up(xxl) {
			height: 400px;
		}

		:deep(img) {
			width: 100%;

			@include media-breakpoint-up(lg) {
				width: fit-content;
				position: absolute;
				right: 0;
			}
			@include media-breakpoint-up(xxl) {
				width: 45%;
				height: 400px;
			}
		}
	}
	.image-left {
		:deep(img) {
			left: 0;
		}
	}
}
.reversed {
	@include media-breakpoint-up(lg) {
		flex-direction: row-reverse;
	}
}
</style>
<style lang="scss">
.usage-info-col {
	position: initial !important;
}
</style>
